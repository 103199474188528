import React from 'react';

export default class ColorfulLineLoader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
  }

  componentDidMount() {
    if (this.props.autoHide) {
      this.timer = setTimeout(() => {
        this.setState({ visible: false });
      }, this.props.duration || 5000); // 默认3秒
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    if (!this.state.visible && this.props.autoHide) {
      return null;
    }

    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
}
